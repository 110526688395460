import { Footer, SEO } from 'components';
import { motion } from 'framer-motion';
import { FormattedMessage, navigate, useIntl } from 'gatsby-plugin-intl';
import PageTransition from 'gatsby-v2-plugin-page-transitions';
import { CupSizes } from 'modules/measurements';
import { MeasurementThunkActions } from 'modules/measurements/redux/thunks';
import { AppState } from 'modules/redux-store';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cta, measure, spacing, titles, utils } from 'style';

const CupSize: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const cupSize = useSelector((state: AppState) => state.measurements.cupSize);
  const [error, setError] = useState<string>();

  const buttonAnimation = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 40 },
  };

  return (
    <PageTransition>
      <SEO
        title={intl.formatMessage({ id: 'cupSizePageSeo' })}
        url="https://find-your-fit.eu/en/cup-size"
        urlEN="https://find-your-fit.eu/en/cup-size"
        urlDE="https://find-your-fit.eu/de/cup-size"
        urlIT="https://find-your-fit.eu/it/cup-size"
        urlNL="https://find-your-fit.eu/nl/cup-size"
        urlFR="https://find-your-fit.eu/fr/cup-size"
      />
      <div css={utils.wrapper}>
        <section css={[measure.root]}>
          <motion.div
            css={measure.visual}
            animate={{ opacity: [0, 1] }}
            transition={{ duration: 1.5, ease: [0.22, 1, 0.36, 1] }}
          >
            <img
              src={require('assets/images/measure/measure-step2b.gif')}
              alt=""
            />
          </motion.div>

          <motion.h1
            css={[measure.title, titles.primary]}
            animate={{ y: [40, 0], opacity: [0, 1] }}
            transition={{ delay: 0.5, duration: 1, ease: [0.22, 1, 0.36, 1] }}
          >
            <FormattedMessage id="stepTwo" />
            <br />
            <span css={utils.fontFamilySansAlt}>
              <FormattedMessage id="cupSize" />.
            </span>
          </motion.h1>

          <motion.div
            css={measure.content}
            animate={{ y: [40, 0], opacity: [0, 1] }}
            transition={{ delay: 0.5, duration: 1, ease: [0.22, 1, 0.36, 1] }}
          >
            <p css={[utils.typeLarge, utils.fontFamilySansAlt]}>
              A, B, C, D, E
            </p>
          </motion.div>

          <form onSubmit={handleClickNext}>
            <motion.div
              css={measure.field}
              animate={{ y: [40, 0], opacity: [0, 1] }}
              transition={{ delay: 1, duration: 1, ease: [0.22, 1, 0.36, 1] }}
            >
              <input
                onChange={handleInputChange}
                id="cupSize"
                name="cupSize"
                type="text"
                css={measure.input}
                placeholder="A"
              />
              <p
                css={[
                  utils.typeLarge,
                  utils.fontFamilySansAlt,
                  utils.typeUpper,
                ]}
              >
                <FormattedMessage id="cupSize" />
              </p>
            </motion.div>
            <div css={measure.validation}>
              {error && (
                <p
                  css={[
                    utils.messageWarning,
                    spacing.top.medium,
                    utils.typeCenter,
                  ]}
                >
                  <FormattedMessage id="cupSizeMissing" />
                </p>
              )}
            </div>

            <motion.button
              animate={cupSize ? 'visible' : 'hidden'}
              variants={buttonAnimation}
              disabled={!cupSize || !isCupSize(cupSize)}
              css={[cta.button, cta.buttonHiddenByDefault]}
            >
              <FormattedMessage id="ctaNext" />
            </motion.button>
          </form>
        </section>
      </div>
      <Footer />
    </PageTransition>
  );

  function handleClickNext(e: React.MouseEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!cupSize) {
      setError('Please enter your cup size');
      return;
    } else {
      navigate('/select-your-sports');
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;

    if (error) {
      setError(undefined);
    }

    /** Compare and save values of cup sizes always in uppercase. */
    if (value && !isCupSize(value)) {
      setError('Cup sizes are available between A-E');
    }

    dispatch(MeasurementThunkActions.updateCupSize(value.toUpperCase()));
  }

  function isCupSize(input: string | CupSizes): input is CupSizes {
    const inputQuery = input.toUpperCase() as CupSizes;
    return (
      inputQuery === 'A' ||
      inputQuery === 'B' ||
      inputQuery === 'C' ||
      inputQuery === 'D' ||
      inputQuery === 'E'
    );
  }
};

export default CupSize;
